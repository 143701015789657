import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  name?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  error?: boolean;
  helperText?: string | null;
  mask: string | (string | RegExp)[];
};

export const InputPhone = ({
  value,
  mask,
  onChange,
  label,
  error = false,
  helperText,
  name,
  onBlur,
}: Props) => {
  const { t } = useTranslation();
  return (
    <InputMask name={name} mask={mask} value={value} onChange={onChange} onBlur={onBlur}>
      {(inputProps: any) => (
        <TextField
          {...inputProps}
          label={label ?? t('input-phone.label')}
          variant="standard"
          fullWidth
          error={error}
          helperText={helperText}
          sx={{
            '& label.Mui-focused': {
              color: '#8B8D92',
            },
          }}
          inputProps={{
            inputMode: 'tel',
            style: {
              fontVariant: 'tabular-nums',
            },
          }}
        />
      )}
    </InputMask>
  );
};
